@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Pixelify+Sans:wght@700&family=Russo+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

* {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
}

@font-face {
  font-family: "Cairo", sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Pixelify+Sans:wght@700&family=Russo+One&display=swap');
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

* {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
  animation: fadeIn 1s ease-in-out;
}

/* Apply the animation to the body */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #e5e6f9;
}

::-webkit-scrollbar-thumb {
  background-color: #303468;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom styles for the progress bar */
.progress-bar {
  --progress-height: 1rem;
  /* Change this value to adjust the height */
  --progress-bg: #e5e5e5;
  /* Background color */
  --progress-bar-bg: #062841;
  /* Progress bar color */
  --progress-radius: 0.5rem;
  /* Border radius */
  --progress-value: 44%;
  /* Default progress value */
  width: 100%;
  height: var(--progress-height);
  background-color: var(--progress-bg);
  border-radius: var(--progress-radius);
  overflow: hidden;
  position: relative;
}

.progress-bar::before {
  content: "";
  display: block;
  height: 100%;
  background-color: var(--progress-bar-bg);
  width: var(--progress-value);
  position: absolute;
  right: 0;
  /* Align the progress bar to the right */
  transition: width 0.3s ease;
}